* {
    margin: 0px;
    padding: 0px;
    border: 0px;
    text-decoration: none;
}

*:focus {
    outline: 0;
}

u {
    text-decoration: underline;
}

s {
    text-decoration: line-through;
}

.noBorder {
    border: none !important;
    margin-bottom: 6px !important;
}

#container, #headWrapper {
    width: 1200px;
    margin: 0 auto;
}

a.disabled {
    color: #868786 !important;
    pointer-events: none;
}

#headWrapper {

}

#titleBar {
    overflow: hidden;
    padding: 0 50px 23px 50px;
}

#titleBar #logo {
    float: left;
    margin-top: 76px;
}

#titleBar #logoBackend {
    float: left;
    margin-top: 44px;
    margin-bottom: 16px;
}

#titleBar #currentConferenceTitle{
    color: #d9272e;
    font-family: Titillium-Regular;
    font-size: 16px;
    margin-top: 69px;
    margin-left: 20px;
    float: right;
}

#titleBar h5 {
    font-family: TitilliumText22L004;
    color: #d9272e;
    text-decoration: none;
    /*position: relative;
    top: -22px;*/
    font-size: 18px;
    float: left;
}

#titleBar h5 a {
    color: #d9272e;
}

#titleBar .titleBarLine {
    width: 100%;
    overflow: hidden;
}

#titleBar #navbar {
    float: right;
    margin-top: 100px;
    font-family: TitilliumText22L004;
    font-size: 18px;
    color: #888;
}

#titleBar .titleBarLine #navbar {
    margin-top: 0px;
}

#titleBar #navbar a {
    color: #d9272e;
    text-decoration: none;
}

#banner {
    background-image: url('../../img/panorama.jpg');
    width: 1200px;
    height: 400px;
}

#banner #leftCol {
    float: left;
}

#banner #rightCol {
    float: right;
    width: 443px;
    height: 400px;
    margin-right: 36px;
    background-color: #c9060ee5;
}

#banner #leftCol p {
    width: 490px;
    color: #fff;
    font-family: Titillium-Semibold;
    font-weight: 600;
    font-size: 50px;
    line-height: 60px;
    margin-left: 50px;
    margin-top: 207px;
}

#banner #rightCol p {
    width: 414px;
    padding: 8px 0 4px 0;
    margin-left: 14px;
    margin-top: 246px;
    font-family: Titillium-Semibold;
    /* font-weight: 500; */
    color: #fff;
    font-size: 28px;
    line-height: 34px;
    border-top: 1px #fff solid;
    border-bottom: 1px #fff solid;
}

#banner #rightCol p a{
    color: #fff;
    text-decoration: underline;
}

#contentHeader {
    min-height: 55px;
}

#contentWrapper {
    width: 1100px;
    padding: 0 50px 55px 50px;
    min-height: 420px;
}

/*#contentWrapper p:not(.ql-editor *){
    color: #d9272e;
    font-family: Titillium-Regular;
    font-size: 18px;
}*/

#contentWrapper h1:not(.ql-editor *) {
    font-family: Titillium-Semibold;
    /* font-weight: 600; */
    font-size: 28px;
    color: #d9272e;
    display: block;
    border-bottom: 1px #d9272e solid;
    width: 100%;
    margin-top: 20px;
    margin-bottom: 23px;
    padding-bottom: 11px;
    font-weight: normal;
}

#contentWrapper h2:not(.ql-editor *) {
    font-family: Titillium-Semibold;
    /* font-weight: 600; */
    font-size: 20px;
    color: #d9272e;
    display: block;
    border-bottom: 1px #d9272e solid;
    width: 100%;
    margin-top: 20px;
    margin-bottom: 23px;
    padding-bottom: 4px;
    font-weight: normal;
}

#contentWrapper h3:not(.ql-editor *) {
    font-family: Titillium-Semibold;
    font-weight: normal;
    /* font-weight: 600; */
    font-size: 16px;
    color: #d9272e;
    display: block;
    border-bottom: 1px #d9272e solid;
    width: 100%;
    margin-top: 20px;
    margin-bottom: 23px;
    padding-bottom: 4px;
    text-transform: uppercase;
}

#contentWrapper h4.todo {
    font-family: Titillium-Semibold;
    /* font-weight: 600; */
    font-size: 20px;
    color: #d9272e;
    display: block;
    border-bottom: 1px #d9272e solid;
    width: 100%;
    margin-top: 20px;
    margin-bottom: 10px;
    padding-bottom: 4px;
    font-weight: normal;
}

#contentWrapper h5.todo {
    font-family: Titillium-Semibold;
    font-size: 28px;
    color: #d9272e;
    display: block;
    width: 100%;
    margin-top: 20px;
    margin-bottom: 23px;
    padding-bottom: 11px;
    font-weight: normal;
}

.formH3Container {
    display: block;
    border-bottom: 1px #d9272e solid;
    width: 100%;
    margin-top: 20px;
    margin-bottom: 23px;
    /* padding-bottom: 4px; */
    overflow: hidden;
}

.formH3Container h3 {
    display: inline !important;
    border-bottom: none !important;
}

.formButton {
    float: right;
    width: 25px;
    height: 26px;
    position: relative;
    bottom: 1px;
    cursor: pointer;
}

.formButtonSpacer {
    width: 5px;
    height: 26px;
    float: right;
}

.formButtonRemove {
    background-image: url('../../img/button_minus.png');
}

.formButtonAdd {
    background-image: url('../../img/button_plus.png');
}

#stepIconWrapper {

    width: 100%;
    overflow: hidden;
}

.stepIconContainer {
    float: left;
    width: 25%;
}

.stepIconContainer.steps3 {
    width: 33%;
}

.stepIconContainer img {

    display: block;
    margin: 0 auto;
}

.stepIconContainer .stepIconTitle {
    font-size: 12px;
    font-family: Titillium-Bold_0;
    color: #858686;
    text-align: center;
    padding-top: 12px;
}

.stepIconContainer .stepIconTitle.stepDone {
    color: #d9272e;
}

.userFile{
    display: inline-block;
    margin-bottom: 0.8rem;
    overflow: hidden;
}

.userFile .fileIcon{
    display: inline-block;
    margin-right: 3px;
    width: 16px;
}

.userFile .fileName{
    display: inline-block;
    overflow: hidden;
    width: 427px;
    text-overflow: ellipsis;
    white-space: nowrap;
    position: relative;
    top: 5px;
}

.mediumWrapper {
    width: 670px;
}

.halfColumnWrapper {

    overflow: hidden;
    padding-bottom: 20px;
}

.halfColumn {
    width: 528px;
}

.halfColumnLeft {
    float: left;
}

.halfColumnRight {
    float: right;
}

.halfColumn label {
    width: 80px;
    text-align: left;
}

.halfColumn input, .halfColumn textarea, .halfColumn select {
    width: 420px !important;
}

.pseudoInput {
    width: 450px;
    display: inline-block;
    padding: 0 10px;
    font-family: TitilliumText22L004;
    font-size: 16px;
    color: #000;
    line-height: 20px;
    box-sizing: border-box;
    margin-top: 3px;
}

.halfColumn span.pseudoInput {
    width: 420px;
}

#contentWrapper > p, .mediumWrapper > p, form > p, #formBottom {
    font-family: Titillium-Regular;
    font-size: 20px;
    width: 100%;
}

.mediumWrapper > ul.bulletList {
    font-family: Titillium-Regular;
    font-size: 20px;
}

.mediumWrapper > ul.bulletList li {
    margin-left: 40px;
}

#contentWrapper p a, #formBottom a {
    text-decoration: none;
    color: #d9272e;
}

#contentWrapper p a.disabled, #formBottom a.disabled {
    color: #868786;
    pointer-events: none;
}

#contentWrapper p a:hover, #formBottom a:hover {
    text-decoration: none;
    color: #868786;
}

#contentWrapper p a span.linkText, #formBottom a span.linkText {
    font-family: Titillium-Semibold;
}

#contentWrapper p a span.linkImg, #formBottom a span.linkImg {
    height: 30px;
    width: 30px;
    background-image: url("../../img/arrow-right.png");
    display: inline-block;
    margin-left: 10px;
    margin-right: 7px;
    vertical-align: middle;
    position: relative;
    bottom: 2px;
}

#contentWrapper p a:hover span.linkImg, #formBottom a:hover span.linkImg {
    background-image: url("../../img/arrow-right-grey.png");
}

#contentWrapper p a.disabled span.linkImg, #formBottom a.disabled span.linkImg {
    background-image: url("../../img/arrow-right-grey.png");
}

#contentWrapper p a span.linkImg {
    height: 30px;
    width: 30px;
    background-image: url("../../img/arrow-right.png");
    display: inline-block;
    margin-left: 10px;
    margin-right: 7px;
    vertical-align: middle;
    position: relative;
    bottom: 2px;
}

#formBottom a span.linkImgLeft {
    height: 30px;
    width: 30px;
    background-image: url("../../img/arrow-left.png");
    display: inline-block;
    margin-left: 10px;
    margin-right: 7px;
    vertical-align: middle;
    position: relative;
    bottom: 2px;
}

#contentWrapper p a:hover span.linkImg {
    background-image: url("../../img/arrow-right-grey.png");
}

#formBottom a:hover span.linkImgLeft {
    background-image: url("../../img/arrow-left-grey.png");
}

#contentWrapper p a.disabled span.linkImg {
    background-image: url("../../img/arrow-right-grey.png");
}

#formBottom a.disabled span.linkImgLeft {
    background-image: url("../../img/arrow-left-grey.png");
}

.formLine {
    padding-bottom: 5px;
    /* background-color: #888; */
    width: 670px;
    overflow: hidden;
}

.formLine.wide {
    width: 100%;
}

.checkBoxLine {
    padding-bottom: 10px;
}

.organizedSessionWrapper {
    border: 1px rgba(184, 28, 43, 0.28) solid;
    width: 670px;
    padding: 20px;
    margin-bottom: 60px;
}

.abstractsWrapper {
    border: 1px rgba(184, 28, 43, 0.28) solid;
    width: 670px;
    padding: 20px;
    margin-bottom: 60px;
}

.formLine h4 {
    font-family: Titillium-Semibold;
    font-weight: normal;
    /* font-weight: 600; */
    font-size: 16px;
    color: #d9272e;
    display: block;
    border-bottom: 1px #d9272e solid;
    width: 100%;
    margin-top: 20px;
    margin-bottom: 23px;
    padding-bottom: 4px;
}

.formLine p {
    padding-left: 220px;
    width: 450px;
    color: #d9272e;
    font-family: Titillium-Regular;
    font-size: 18px;
}

.formLine ul {
    padding-left: 220px;
    width: 450px;
    color: #d9272e;
    font-family: Titillium-Regular;
    font-size: 18px;
}

.formLine ul li {
    margin-left: 30px;
}

.formLine .innerHeadline {
    font-weight: 700;
    font-size: 16px;
    margin-top: 5px;
    font-family: TitilliumText22L004;
}

span.innerReviewerName {
    color: #d9272e;
    font-family: Titillium-Regular;
    font-size: 18px;
    vertical-align: top;
    display: inline-block;
    padding-top: 2px;
}

.formLink {
    text-align: right;
    padding-bottom: 10px;
}

.formLink a {
    font-family: Titillium-Regular;
    font-size: 16px;
    color: #868786;
    text-decoration: none;
}

.formLink a:hover {
    color: #d9272e;
}

.formSpacer {
    padding-top: 50px;
}

.formSpacerSmall {
    padding-top: 10px;
}

#formBottom {
    padding: 0px;
}

#formBottom > #formBottomGradient {
    height: 10px;
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffffff+0,ffffff+98&amp;0+0,1+100 */
    /*background: -moz-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.98) 98%, rgba(255, 255, 255, 1) 100%);
    background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.98) 98%, rgba(255, 255, 255, 1) 100%);
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.98) 98%, rgba(255, 255, 255, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00ffffff', endColorstr='#ffffff', GradientType=0);*/
}

#formBottom.scroll-to-fixed-fixed {
    background-color: #fff;
    border-top: 1px #d9272e solid;
}

#formBottom > #formBottomContent {
    background-color: #fff;
    padding: 5px 0 10px 0;
    overflow: hidden;
}

.threeColsWrapper {
    overflow: hidden;
}

.threeColsWrapper > div:nth-child(1), .threeColsWrapper > div:nth-child(3) {
    width: 20%;
    float: left;
}

.threeColsWrapper > div:nth-child(2) {
    width: 60%;
    float: left;
}

ul.pagination {
    list-style-type: none;
    overflow: hidden;
    font-size: 14px;
    margin: 0px;
    padding: 0px;
    text-align: center;
}

ul.pagination:hover {
    cursor: default;
}

ul.pagination li {
    display: inline;
    margin: 5px;
}

ul.pagination li input, ul.pagination li button {
    background: none;
    font-family: Titillium-Light;
    color: #d9272e;
    margin: 0px;
    padding: 0px;
}

ul.pagination li input:hover, ul.pagination li button:hover {
    color: #868786;
    cursor: pointer;
}

ul.pagination li .inactivePaginationLink {
    color: #868786;
    cursor: default;
}

ul.pagination li .activePaginationLink {
    color: #484848;
    cursor: default;
    letter-spacing: 1px;
}

ul.pagination li input, ul.pagination li a {
    background: none;
    font-family: Titillium-Light;
    color: #d9272e;
    margin: 0px;
    padding: 0px;
}

ul.pagination li a:hover {
    color: #868786;
    cursor: pointer;
}

label {
    color: #d9272e;
    font-family: TitilliumText22L004;
    font-size: 16px;
    display: inline-block;
    width: 190px;
    text-align: right;
    margin-right: 24px;
    vertical-align: top;
    margin-top: 5px;
    float: left;
}

input[type=text],
input[type=email],
input[type=password],
select {
    height: 25px;
    width: 450px;
    padding: 0 10px;
    border: 1px #d9272e solid;
    background-color: #fff;
    font-family: TitilliumText22L004;
    font-size: 16px;
    color: #000;
    line-height: 20px;
    box-sizing: border-box;
    float: right;
}

input[type=file] {
    color: #d9272e;
}

.formLine.wide input[type=text],
.formLine.wide input[type=email],
.formLine.wide input[type=password],
.formLine.wide select {
    width: 880px;
}

select {
    padding: 0 5px;
}

textarea {
    height: 250px;
    width: 450px;
    padding: 15px 10px;
    border: 1px #d9272e solid;
    background-color: #fff;
    font-family: TitilliumText22L004;
    font-size: 16px;
    color: #000;
    line-height: 20px;
    box-sizing: border-box;
    float: right;
}

.formLine.wide textarea {
    width: 880px;
    height: 600px;
}

.formLine.wide textarea.smallTextArea {
    height: 250px;
}

input:disabled, textarea:disabled, select:disabled {
    border: none;
    color: #000 !important;
}

textarea:disabled {
    padding: 3px 10px;
}

textarea.bigTextArea {
    height: 400px;
}

::-webkit-input-placeholder { /* WebKit, Blink, Edge */
    color:    #808080;
    font-style: italic;
}

:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
    color:    #808080;
    font-style: italic;
    opacity:  1;
}
::-moz-placeholder { /* Mozilla Firefox 19+ */
    color:    #808080;
    font-style: italic;
    opacity:  1;
}
:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color:    #808080;
    font-style: italic;
}
::-ms-input-placeholder { /* Microsoft Edge */
    color:    #808080;
    font-style: italic;
}


.charCountWrapper {
    overflow: hidden;
    font-family: Titillium-Light;
    font-size: 16px;
    color: #858686;
    width: 450px;
    float: right;
}

.charCountWrapper span {
    float: right;
}

button[type=submit] {
    background-repeat: no-repeat;
    background-color: #fff;
    font-family: TitilliumText22L004;
    color: #d9272e;
    font-size: 20px;
    line-height: 32px;
    height: 30px;
}

button[type=submit].disabled {
    background-image: url("../../img/arrow-right-grey.png") !important;
    color: #868786;
    cursor: not-allowed !important;
}

button[type=submit].right {
    background-image: url("../../img/arrow-right.png");
    background-position: right;
    padding-right: 36px;
    float: right;
}

button[type=submit].right:hover {
    background-image: url("../../img/arrow-right-grey.png");
    color: #868786;
    cursor: pointer;
}

button[type=submit].left {
    background-image: url("../../img/arrow-left.png");
    background-position: left;
    padding-left: 36px;
    float: left;
    margin-left: 220px;
}

button[type=submit].left:hover {
    background-image: url("../../img/arrow-left-grey.png");
    color: #868786;
    cursor: pointer;
}

button[type=submit].left.noMarging {
    margin: 0px;
}

button[type=submit].inline {
    background-image: url("../../img/arrow-right.png");
    background-position: right;
    padding-right: 36px;
}

button[type=submit].inline:hover {
    background-image: url("../../img/arrow-right-grey.png");
    color: #868786;
    cursor: pointer;
}

.styledCheckbox > input[type=checkbox] {
    visibility: hidden;
    float: right;
}

.styledCheckbox {
    width: 450px;
    margin: 0px 0px;
    position: relative;
    display: inline;
    overflow: hidden;
    float: right;
}

.styledCheckbox label {
    cursor: pointer;
    /* position: relative; */
    width: 20px;
    height: 20px;
    top: 0;
    border: 1px #d9272e solid;
    margin: 0px;
}

.styledCheckbox label:after {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
    opacity: 0;
    content: '';
    position: absolute;
    width: 9px;
    height: 5px;
    background: transparent;
    top: 5px;
    left: 5px;
    margin: 0px;
    padding: 0px;
    border: 3px solid #d9272e;
    border-top: none;
    border-right: none;
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg);
}

.styledCheckbox label:hover::after {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=10)";
    filter: alpha(opacity=10);
    opacity: 0.1;
}

.styledCheckbox input[type=checkbox]:checked + label:after {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    filter: alpha(opacity=100);
    opacity: 1;
}

@media \0screen\,screen\9 {
    /* IE6-8 */
    .styledCheckbox > input[type=checkbox] {
        visibility: visible;
        float: none;
    }

    .styledCheckbox label {
        display: none;
    }
}

@media screen and (min-width: 0\0
) and (min-resolution: +72dpi) {
    /* IE9+ */
    .styledCheckbox label:after {
        /*top: 1px;*/
    }
}

.styledCheckboxLabel {
    color: #d9272e;
    font-family: TitilliumText22L004;
    font-size: 16px;
    margin-left: 7px;
    display: inline-block;
    margin-top: 5px;
    max-width: 405px;
}

.chosen-select, ul.select2-choices {
    width: 440px !important;
}

.inlineForm input[type=text]{
    float: none;
    width:90%;
}

.inlineForm input[type=submit]{
    background-color: #d9272e;
    color: white;
    font-family: Titillium-Semibold;
    font-size: 17px;
    padding: 0px 9px;
    height: 23px;
    cursor: pointer;
}

.inlineForm select{
    float: none;
    width: auto;
}

table.list {

    width: 100%;

    margin-top: 45px;
}

table.backendList {
    margin-top: 5px;
}

table.list tr {

}

table.list tr th {
    border-top: 1px #d9272e solid;
    border-bottom: 1px #d9272e solid;
    font-family: Titillium-Semibold;
    font-size: 20px;
    text-align: left;
    color: #d9272e;
}

table.backendList tr th {
    border-top: none;
    border-bottom: none;
}

table.backendList tr td {
    border-bottom: 1px transparent solid;
}

table.backendList tr:hover td {
    background-color: rgb(249, 249, 249);
}

table.backendList span.categoryId{
    border-bottom: 1px dashed #616161;
    cursor: help;
}

div.filterWrapper{
    float: right;
    margin-right: 24px;
    padding-bottom: 20px;
}

div.filterWrapper label{
    float: none;
    text-align: left;
    width: auto;
}

div.filterWrapper input{
    float: none;
    width: 200px;
}

div.filterWrapper input[type=image]{
    width: auto;
    display: inline-block;
}

div.filterWrapper input[type=image].magnifier{
    position: relative;
    top: 4px;
    margin-left: 5px;
}

table.list tr th.disabled {

    color: #818181;
}

table.list tr th.titleSpeaker {
    width: 360px;
}

table.list tr th.created {
    width: 105px;
}

table.list tr th.statusTick {
    width: 125px;
}

table.list tr th.editStatus {
    width: 69px;
}

table.list tr td {
    font-family: Titillium-Light;
    font-size: 16px;
    line-height: 19px;
    padding-top: 10px;
}

table.list tr td img {
    margin-left: 5px;
}

table.smallFontList tr th{
    font-size: 16px;
}

table.smallFontList tr td{
    font-size: 13px;
    padding: 10px 0;
    border-bottom: 1px rgba(184, 28, 43, 0.14) solid;
}

.smallFontList input{
    font-size: 12px;
}
.smallFontList select{
    font-size: 12px;
    padding: 0 2px;
}

.abstractList{

}

table.abstractList tr td{
    padding: 15px 0;
    border-top: 1px rgba(184, 28, 43, 0.14) solid;
}

table.abstractList tr td.titleColumn{
    padding-right: 15px;
}

table.abstractList tr td.speakerColumn{
    width: 160px;
    padding-right: 10px;

}

#legend {
    font-family: Titillium-Light;
    font-size: 16px;
    margin-top: 70px;
}

#legend span {

    display: inline-block;
    margin-right: 50px;
}

#footerWrapper {
    height: 250px;
    width: 100%;
}

#footerWrapper .footerContainer {
    width: 1150px;
    margin: 0 auto;
    padding-left: 50px;
}

#footerWrapper #topBar {
    background-color: #424242;
    padding: 10px 50px 17px;
}

#footerWrapper #topBar h1 {
    margin: 0px;
    padding: 0px;
    font-size: 16px;
    font-family: Titillium-Bold_0;
    font-weight: normal;
    color: #FFF;
    margin-top: 14px;
    margin-bottom: 0px;
}

#footerWrapper #topBar p {
    margin: 0px;
    font-family: Titillium-Light;
    font-size: 16px;
    line-height: 20px;
    padding: 0px 0 0;
    color: #FFF;
}

#footerWrapper #topBar a {
    color: #fff;
    font-family: Titillium-Bold_0;
    font-size: 16px;
    /* font-weight: bold; */
    text-decoration: none;
}

#bottomBar {
    background-color: #d9272e;
    /* width: 1200px; */
    height: 100px;
    /* margin-bottom: 100px; */
    overflow: hidden;
    padding: 0 50px;
    box-shadow: inset 0 16px 9px -7px rgba(0, 0, 0, 0.28);
}

#footerWrapper #bottomBar #bottomLinks {
    float: right;
    padding-right: 50px;
    padding-top: 24px;
}

#footerWrapper #bottomBar #bottomLinks #euheaSmallLogo {
    margin-right: 28px;
}

#footerWrapper #bottomBar #bottomLinks #twitterLogo {
}

#footerWrapper #bottomBar #copyright {
    float: left;
    color: #fff;
    font-family: Titillium-Light;
    font-size: 16px;
    padding-top: 24px;
}

#_coauthor-form_template {
    display: none;
}

.coauthor-form:first-of-type {
    padding-top: 0px;
}

.coauthor-form {
    padding-top: 30px;
    background-color: #fff;
}

.coauthor-form .handleWrapper {
    position: absolute;
    padding-left: 15px;
    height: 180px;
    border-left: 1px rgba(184, 28, 43, 0.23) solid;
}

.coauthor-form .handleWrapper .remove{
    width: 40px;
    height: 40px;
    cursor: pointer;
    background-repeat: no-repeat;
    background-position: 2px 50%;
    background-image: url(../../img/button_minus.png);
    margin-left: 3px;
    margin-top: -7px;
}

.coauthor-form .handleWrapper .handle{
    width: 40px;
    height: 40px;
    cursor: move;
    background-image: url(../../img/sort-sw.png);
    background-repeat: no-repeat;
    background-position: 6px 50%;
    margin-top: 7px;
}

table.list tr th.abstractsCount {
    width: 100px;
}

#legend span img {
    vertical-align: middle;
}

.formError {
    background-color: rgb(247, 228, 230) !important;
}

.formErrorMessageWrapper {
    background-color: #F7E4E6;
    margin: 27px 0px;
    padding: 14px 20px;
    overflow: hidden;
    border: 1px #E4D7D9 solid;
}

.formErrorMessageWrapper h4 {
    font-family: Titillium-Semibold;
    font-size: 20px;
    color: #d9272e;
    /* margin-top: 0px; */
    /* margin-bottom: 23px; */
    padding-bottom: 4px;
}

.formErrorMessageWrapper div.icon {
    font-family: Titillium-Semibold;
    font-size: 56px;
    height: 56px;
    text-align: center;
    width: 56px;
    background-color: #FFB2BA;
    color: #d9272e;
    float: left;
    /* padding: 0px 6px 0 5px; */
    margin-right: 17px;
}

.formErrorMessageWrapper span.errorMessage {
    font-family: Titillium-Regular;
    font-size: 17px;
    width: 100%;
    color: #d9272e;
}

.formErrorMessageWrapper span.errorMessage div.errorListWrapper {
    overflow: hidden;
    padding-left: 18px;
}

.flashMessageWrapper {
    margin: 23px 0px;
    padding: 6px 20px;
    overflow: hidden;
    box-sizing: border-box;
}

.flashMessageWrapper.flashSuccess {
    font-family: Titillium-Regular;
    font-size: 17px;
    width: 100%;
    color: #295F37;
    background-color: #CEECD6;
    /*border: 1px #D3E2D7 solid;*/
}

.flashMessageWrapper.flashInfo {
    font-family: Titillium-Regular;
    font-size: 17px;
    width: 100%;
    color: #27525F;
    background-color: #DCF1F7;
    /*border: 1px #E3E9EA solid;*/
}

.flashMessageWrapper.flashWarning {
    font-family: Titillium-Regular;
    font-size: 17px;
    width: 100%;
    color: #d9272e;
    background-color: #F7E4E6;
    /*border: 1px #E4D7D9 solid;*/
}

#menu {
}

#menu ul {
    list-style-type: none;
    list-style-image: none;
    margin: 0px;
    padding: 0px;
}

#menu li.topmenu {
    float: left;
}

.topmenu a {
    float: left;
    text-align: center;
}

.topmenu ul {
    display: none;
    position: absolute;
    margin-top: 20px !important;
}

#menu li.topmenu:hover > a {
    color: #868786 !important;
}

.topmenu a, .submenu a {
    padding: 1px 5px;
    border-collapse: collapse;
    font-weight: bold;
    text-decoration: none;
    margin: 0;
}

.submenu {
    overflow: hidden;
    padding: 4px 0 0 0;
}

.submenu a {
    position: relative;
    clear: both; /* special IE6 */
    font-size: 16px;
}

#menu a:hover, .topmenu.on a {
    color: #868786 !important;
}

.topmenu:hover ul {
    display: block;
    z-index: 500;
    padding-bottom: 5px !important;
    background-color: #fff;
}

.menuSpacer {
    float: left;
}

div.textPreviewWrapper {
    position: relative;

}

div.textPreview {
    height: 145px;
    overflow: hidden;
}

div .readMore {
    opacity: 0;
    position: absolute;
    bottom: 0;
    left: 220px;
    width: 445px;
    text-align: center;
    margin: 0;
    padding: 100px 0 0 0;

    /* "transparent" only works here because == rgba(0,0,0,0) */
    background-image: linear-gradient(to bottom, transparent, white);
}

div .readMore .expandTextPreview {
    width: 30px;
    height: 18px;
    background-image: url("../../img/expand.png");
    display: inline-block;
    position: relative;
    top: 4px;
    cursor: pointer;
}

div.textPreview .readMore {
    opacity: 1;
}

p.reduceTextPreviewWrapper {
    text-align: center;
    position: relative;
}

p.reduceTextPreviewWrapper .reduceTextPreview {
    width: 30px;
    height: 18px;
    background-image: url("../../img/reduce.png");
    display: inline-block;
    cursor: pointer;
    position: relative;
    top: 10px;
}

a.activeSortByLink {
    font-weight: bold;
}

.rankWrapper {
}

.rankWrapper .rankItem {
    border: 1px solid rgba(0, 0, 0, 0.28);
    margin-bottom: 5px;
    width: 525px;
    height: 75px;
    overflow: hidden;
    cursor: default;
}

.rankWrapper .rankItem.organizedSession {
    height: 150px;
}

.rankWrapper .rankItemPlaceholder {
    width: 525px;
    height: 10px;
    background-color: #bbc4db;
    margin-bottom: 5px;
}

.rankWrapper .rankItem .handle {
    float: left;
    width: 40px;
    height: 100%;
    cursor: move;
    background-image: url('../../img/sort-sw.png');
    background-repeat: no-repeat;
    background-position: 6px 50%;
    background-color: rgb(236, 236, 236);
}

.rankWrapper .rankItem label .check {
    width: 40px;
    height: 100%;
    padding: 0px;
}

.rankWrapper .rankItem label .check img {
    margin: 26px 5px;
}

.rankWrapper .rankItem label {
    float: left;
    width: 40px;
    height: 100%;
    cursor: pointer;
    background-color: rgb(236, 236, 236);
    background-image: url('../../img/check_big.png');
    background-position: center;
    background-repeat: no-repeat;
    margin: 0;
}

.rankWrapper .rankItem label [type="checkbox"] {
    display: none;
}

.rankWrapper .rankItem label [type="checkbox"] + div {
    display: inline-block;
}

.rankWrapper .rankItem label :checked + div {
    background: #d9272e;
    background-image: url('../../img/check_big.png');
    background-position: center;
    background-repeat: no-repeat;
    display: inline-block;
}

.rankWrapper .rankItem .title {
    float: left;
    width: 415px;
    padding: 2px 5px;
    font-family: Titillium-Light;
    font-size: 16px;
    line-height: 19px;
    height: 71px;
}

.rankWrapper .rankItem .title.smallLine {
    height: 50px;
}

.rankWrapper .rankItem .speaker {
    height: 21px;
    float: left;
    width: 615px;
    padding: 2px 5px;
    font-family: Titillium-Light;
    font-style: italic;
    font-size: 15px;
    line-height: 19px;
    color: #d9272e;
}

.rankWrapper .rankItem .title ul {
    list-style-type: square;
    list-style-position: inside;
    padding-left: 15px;
}

.rankWrapper .rankItem .title li {
    font-style: italic;
}

.rankWrapper .rankItem .rating {
    font-family: Titillium-Light;
    padding: 2px 0;
    font-size: 20px;
    float: right;
    width: 58px;
    height: 27px;
}

.rankWrapper .rankItem .tools {
    float: right;
    width: 58px;
}

.rankWrapper .rankItem .tools.smallTools {
    width: 25px;
}

.rankWrapper .rankItem .tools .spacer {
    margin-top: 5px;
    width: 58px;
}

.rankWrapper .rankItem .tools img {
    cursor: pointer;
}

.rankWrapper .rankItem .tools .acceptRejectIndicator {
    background-image: url('../../img/accept.png');
    background-repeat: no-repeat;
    height: 16px;
    width: 16px;
    margin-bottom: 8px;
}

.rankWrapper .rankItem.withCategory{
    height: 100px;
}

.rankWrapper .rankItem.withCategory .title{
    font-family: Titillium-Regular;
    overflow: hidden;
}

.rankWrapper .rankItem.withCategory .category {
    float: left;
    width: 415px;
    padding: 2px 5px;
    font-family: Titillium-Light;
    font-size: 15px;
    line-height: 19px;
}

.rankItem.scAcceptedNo .tools .acceptRejectIndicator {
    border-bottom: 4px #d9272e solid;
    padding-bottom: 4px;
    margin-bottom: 4px;
}

.rankItem.scAcceptedMaybe .tools .acceptRejectIndicator {
    border-bottom: 4px #242DB8 solid;
    padding-bottom: 4px;
    margin-bottom: 4px;
}

.rankItem.scAcceptedYes .tools .acceptRejectIndicator {
    border-bottom: 4px #2DA02D solid;
    padding-bottom: 4px;
    margin-bottom: 4px;
}

.rankItem.onScheduleYes {
    border-right: 4px #2DA02D solid;
    border-style: solid !important;
}

.rankWrapper .divider {
    border: 1px solid rgb(234, 166, 166) !important;
    background-color: rgba(232, 159, 159, 0.15);
    margin: 26px 0px;
    height: 60px;
}

.rankWrapper .divider .handle {
    background-image: url('../../img/sort.png');
    background-color: #F5C4C4;
}

.rankWrapper .divider .title {
    font-size: 17px;
    color: #d9272e;
}

.rankWrapper .divider ~ .rankItem {
    border-style: dashed;
}

.rankWrapper .divider ~ .rankItem .handle {
    opacity: 0.8;
}

.rankWrapper .divider ~ .rankItem .tools .acceptRejectIndicator {
    background-image: url('../../img/reject.png') !important;
}

.rankWrapper .dividerTalk ~ .rankItem {
    border-style: dotted;
}

.rankWrapper .dividerTalk ~ .rankItem .handle {
    opacity: 1;
}

.rankWrapper .dividerTalk ~ .rankItem .tools .acceptRejectIndicator {
    background-image: url('../../img/accept-bl.png');
}

.rankWrapper .dividerTalk {
    border: 1px solid rgb(127, 149, 234);
    background-color: rgba(164, 172, 232, 0.15);
    margin: 26px 0px;
    height: 55px;
}

.rankWrapper .dividerTalk .handle {
    background-image: url('../../img/sort-bl.png');
    background-color: #708ef5;
}

.rankWrapper .dividerTalk .title {
    font-size: 17px;
    color: #242db8;
}

.mediumWrapper .rankWrapper .rankItem {
    width: 667px;
    border: 1px #d9272e solid;
}

.mediumWrapper .rankWrapper .rankItem .title {
    width: 615px;
    color: #d9272e;
}

.mediumWrapper .rankWrapper .rankItem .handle {
    background-image: url('../../img/sort.png');
}

.abstractPreviewWrapper {
    height: 500px;
    padding-top: 20px;
    position: relative;
    top: -20px;
}

.abstractPreviewWrapper .abstractPreview {
    border: 1px solid rgba(0, 0, 0, 0.28);
    background-color: #fff;
    display: none;
    width: 486px;
    padding: 0 20px 15px;
    height: 500px;
    overflow-y: auto;
}

.abstractPreviewWrapper .abstractPreview .closeBox {
    float: right;
    position: relative;
    left: 10px;
    top: 5px;
}

.abstractPreviewWrapper .abstractPreview .closeBox span {
    font-family: Titillium-Regular;
    font-size: 18px;
    color: #d9272e;
    cursor: pointer;
}

.abstractPreviewWrapper .abstractPreview .abstract {
    font-family: Titillium-Regular;
    font-size: 18px;
}

.abstractPreviewWrapper .abstractPreview .rating {
    margin-top: 8px;
    font-family: Titillium-Regular;
    font-size: 18px;
}

.abstractPreviewWrapper .abstractPreview .associatedAbstract .title h4 {
    font-family: Titillium-Semibold;
    font-size: 18px;
    color: #d9272e;
    display: block;
    width: 100%;
    margin-top: 20px;
    margin-bottom: 0px;
    padding-bottom: 4px;
    font-weight: normal;
}

.emailPreview {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 14px;
    margin-bottom: 40px;
    padding: 10px;
    border: 1px #d9272e solid;
}

.emailPreview p {
    margin: 8px;
}

.formLine.reviewerRatings .ratingWrapper{
    overflow: hidden;
    margin-top: 5px;
}


ul.categoryList {
    list-style-type: square;
    list-style-position: inside;
    padding-left: 1px;
    color: #252525;
}

ul.categoryList li {
    padding-bottom: 10px;
}

ul.categoryList li a {
    text-decoration: none;
    color: #d9272e;
    font-family: Titillium-Semibold;
    font-size: 20px
}

ul.categoryList li a:hover {
    text-decoration: none;
    color: #868786;
}

.noselect {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}


span.shared-abstract-link {
    display: block;
}

span.shared-abstract-link a:before {
    content: url('/img/document.png');
    display: flex;
    margin-right: 0.5rem;
    margin-top: 0.1rem;
}

span.shared-abstract-link a {
    display: flex;
}


span.shared-abstract-presenter {
    font-size: 80%;
    margin-bottom: 0.3rem;
    display: block;
    /* margin-left: 1.6rem; */
}

span.shared-abstract-title {
    margin-bottom: 0.3rem;
    display: block;
    color: #d9272e;
    margin-bottom: 0.5rem;
}

span.shared-abstract {
    display: block;
    margin-bottom: 1rem;
    border-left: 1px #d9272e solid;
    padding-left: 1rem;
}

.image-select-widget {
    position: relative;
    cursor: crosshair;
    display: inline-block;
    margin: 30px 0;

    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    user-select: none;
}

.image-select-widget img {
    max-width: 100%;
    height: auto;
    pointer-events: none;
}

.image-marker {
    position: absolute;
    height: 8px;
    width: 8px;
    background-color: red;
    border-radius: 50%;
    display: inline-block;
    visibility: hidden;
    margin-left: -4px;
    margin-top: -4px;

    pointer-events: none;
}

@media only screen and (max-width: 1400px) {
    html{
        font-size:100%;
    }

    #container, #headWrapper {
        width: 100%;
        max-width: 100vw;
        margin: 0 auto;
        overflow: hidden;
    }

    #banner {
        background-image: url(../../img/panorama.jpg);
        width: 100%;
        height: calc(400/1200*100vw);
        background-size: contain;
    }

    #banner #leftCol {
        float: left;
    }

    #banner #rightCol {
        float: right;
        width: calc(443/1200*100vw);
        height: calc(400/1200*100vw);
        margin-right: calc(36/1200*100vw);
        background-color: #c9060ee5;
    }

    #banner #leftCol p {
        width: calc(490/1200*100vw);
        font-size: calc(50/1200*100vw);

        line-height: calc(70/1200*100vw);
        margin-left: calc(50/1200*100vw);
        margin-top:calc(180/1200*100vw) !important;
    }


    #banner #rightCol p {
        width: calc(414/1200*100vw);
        padding: 8px 0 4px 0;
        margin-left: calc(14/1200*100vw);
        margin-top: calc(246/1200*100vw);
        font-size: calc(28/1200*100vw);
        line-height: calc(34/1200*100vw);
    }

    /*
    #banner #rightCol p {
        width: 414px;
        padding: 8px 0 4px 0;
        margin-left: 14px;
        margin-top: 246px;
        font-family: Titillium-Semibold;
        color: #fff;
        font-size: 28px;
        line-height: 34px;
        border-top: 1px #fff solid;
        border-bottom: 1px #fff solid;
}
     */

    #banner #rightCol p a{
        color: #fff;
        text-decoration: underline;
    }

    #contentWrapper {
        width: 95% !important;
    }

    .mediumWrapper{
        width: 90% !important;
    }

    #footerWrapper .footerContainer {
        width: 100% !important;
    }

    .formLine {
        width: 100% !important;
    }
}