@font-face {
    font-family: 'MinionPro-Regular';
    src: url('fonts/MinionPro-Regular.eot');
    src: local('☺'), url('fonts/MinionPro-Regular.woff') format('woff'), url('fonts/MinionPro-Regular.ttf') format('truetype'), url('fonts/MinionPro-Regular.svg') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'TitilliumText22L005';
    src: url('fonts/TitilliumText22L005.eot');
    src: local('☺'), url('fonts/TitilliumText22L005.woff') format('woff'), url('fonts/TitilliumText22L005.ttf') format('truetype'), url('fonts/TitilliumText22L005.svg') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'TitilliumText22L004';
    src: url('fonts/TitilliumText22L004.eot');
    src: local('☺'), url('fonts/TitilliumText22L004.woff') format('woff'), url('fonts/TitilliumText22L004.ttf') format('truetype'), url('fonts/TitilliumText22L004.svg') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'TitilliumText22L003_0';
    src: url('fonts/TitilliumText22L003_0.eot');
    src: local('☺'), url('fonts/TitilliumText22L003_0.woff') format('woff'), url('fonts/TitilliumText22L003_0.ttf') format('truetype'), url('fonts/TitilliumText22L003_0.svg') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Titillium-Semibold';
    src: url('fonts/Titillium-Semibold.eot');
    src: local('☺'), url('fonts/Titillium-Semibold.woff') format('woff'), url('fonts/Titillium-Semibold.ttf') format('truetype'), url('fonts/Titillium-Semibold.svg') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Titillium-Regular';
    src: url('fonts/Titillium-Regular.eot');
    src: local('☺'), url('fonts/Titillium-Regular.woff') format('woff'), url('fonts/Titillium-Regular.ttf') format('truetype'), url('fonts/Titillium-Regular.svg') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Titillium-Light';
    src: url('fonts/Titillium-Light.eot');
    src: local('☺'), url('fonts/Titillium-Light.woff') format('woff'), url('fonts/Titillium-Light.ttf') format('truetype'), url('fonts/Titillium-Light.svg') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Titillium-Bold_0';
    src: url('fonts/Titillium-Bold_0.eot');
    src: local('☺'), url('fonts/Titillium-Bold_0.woff') format('woff'), url('fonts/Titillium-Bold_0.ttf') format('truetype'), url('fonts/Titillium-Bold_0.svg') format('svg');
    font-weight: normal;
    font-style: normal;
}
