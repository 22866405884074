.rateit {
    display: -moz-inline-box;
    display: inline-block;
    position: relative;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-touch-callout: none;
}

.rateit.readonly-backend{
    float: right;
    padding-right: 14px;
    margin-top: -7px;
}

.rateit .rateit-range
{
    position: relative;
    display: -moz-inline-box;
    display: inline-block;
    background: url(../../img/star-grey.png);
    height: 20px;
    outline: none;
}

.rateit .rateit-range * {
    display:block;
}

/* for IE 6 */
* html .rateit, * html .rateit .rateit-range
{
    display: inline;
}

/* for IE 7 */
* + html .rateit, * + html .rateit .rateit-range
{
    display: inline;
}

.rateit .rateit-hover, .rateit .rateit-selected
{
    position: absolute;
    left: 0px;
}

.rateit .rateit-hover-rtl, .rateit .rateit-selected-rtl
{
    left: auto;
    right: 0px;
}

.rateit .rateit-hover
{
    background: url(../../img/star.png);
}

.rateit .rateit-hover-rtl
{

}

.rateit .rateit-selected
{
    background: url(../../img/star.png);
}

.rateit .rateit-selected-rtl
{

}

.rateit .rateit-preset
{
    background: url(../../img/star.png);
}

.rateit .rateit-preset-rtl
{
    background: url(../../img/star.png);
}

.rateit button.rateit-reset
{
    background: none;
    width: 15px;
    height: 15px;
    display: -moz-inline-box;
    display: inline-block;
    float: left;
    outline: none;
    border:none;
    padding: 0;
    margin-top: 4px;
    margin-right: 3px;
}

.rateit:hover  button.rateit-reset
{
    background-color: #eaeaea;
}

.rateit button.rateit-reset:hover, .rateit button.rateit-reset:focus
{
    background: url(/img/rect-cross.png) 0 0;
    opacity: 0.6;
    /*background-position: 0 -16px;*/
}

label .rateit{
    margin-top: -7px;
}
