.smoke-base {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  visibility: hidden;
  opacity: 0;
}

.smoke-base.smoke-visible {
  opacity: 1;
  visibility: visible;
}

.smokebg {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.smoke-base .dialog {
  position: absolute;
}

.dialog-prompt {
  margin-top: 15px;
  text-align: center;
}

.dialog-buttons {
  margin: 20px 0 5px 0
}

.smoke {
  font-family: Titillium-Regular;
  text-align: center;
  font-size: 22px;
  line-height: 150%;
}

.dialog-buttons button {
  display: inline-block;
  vertical-align: baseline;
  cursor: pointer;
  font-family: Titillium-Regular;
  font-style: normal;
  text-decoration: none;
  border: 0;
  outline: 0;
  margin: 0 5px;
  -webkit-background-clip: padding-box;
  font-size: 13px;
  line-height: 13px;
  font-weight: normal;
  padding: 9px 12px;
}

.dialog-prompt input {
  margin: 0;
  border: 0;
  font-family: Titillium-Regular;
  outline: none;
  font-family: Titillium-Regular;
  border: 1px solid #aaa;
  width: 75%;
  display: inline-block;
  background-color: transparent;
  font-size: 16px;
  padding: 8px;
}

.smoke-base {
  background: rgba(0,0,0,.3);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=#90000000,endColorstr=#900000000);
}

.smoke-base .dialog {
  top: 25%;
  width: 40%;
  left: 50%;
  margin-left: -20%;
}

.smoke-base .dialog-inner {
  padding: 15px;

  color:#b81c2b;
}

.smoke {
  background-color: rgba(255,255,255,0.95);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=#ffffff,endColorstr=#ffffff);
	box-shadow: 0 2px 8px #666;
}


.dialog-buttons button {
  background-color: #B16169;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=#222222,endColorstr=#222222);
  border-radius: 0;
  color: #fff;
}

.dialog-buttons button:hover {
  background-color: #b81c2b;
}


button.cancel {
  background-color: rgba(0,0,0,.40);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=#444444,endColorstr=#444444);
}

button.cancel:hover {
  background-color: #383838;
}

.queue{
	display:none;
}
