@media print {

    div.textPreview {
        height: auto !important;
    }

    div.textPreview .readMore {
        display: none;
    }

    p.reduceTextPreviewWrapper {
        display: none;
    }

    .selectRating {
        display: inline !important;
        border: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
    }
}
